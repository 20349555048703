import infoSvg from "./info.svg";
import btcSvg from "./btc.svg";
import ethSvg from "./eth.svg";
import bnbSvg from "./bnb.svg";
import usdtSvg from "./usdt.svg";
import usdcSvg from "./usdc.svg";
import cancelInCircleSvg from "./cancel-in-circle.svg";
import PendingIcon from "./PendingIcon";
import ArrowUpSvg from "./arrow-up.svg";
import ArrowDownSvg from "./arrow-down.svg";
import WarningSvg from "./warning.svg";
import CheckGreenSvg from "./check-green.svg";
import CongratsSvg from "./congrats.svg";

const cryptoSvgs: {
  [key: string]: any;
} = {
  BTC: btcSvg,
  ETH: ethSvg,
  BNB: bnbSvg,
  USDT: usdtSvg,
  USDC: usdcSvg,
};

export {
  infoSvg,
  cryptoSvgs,
  cancelInCircleSvg,
  PendingIcon,
  ArrowDownSvg,
  ArrowUpSvg,
  WarningSvg,
  CheckGreenSvg,
  CongratsSvg,
};
