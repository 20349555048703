import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CardInfoPage, ConfirmOrderPage, PaymentSuccessPage } from "pages";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import { PATHS, saasUserCorporationId } from "consts";
import { IUser } from "types";
import "./App.css";
import { VeriffAfterAuth } from "pages/veriff";
import { PendingIcon } from "assets";
import { RoundDown2Digits } from "utils";

function App() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const {
    isLogIn,
    setLogIn,
    saveSsoPayload,
    saveToken,
    saveUser,
    resetCookies,
  } = useContext(PlatformContext);

  const [isCardValid, setIsCardValid] = useState(true);
  const { isLoading } = useSelector((root: RootState) => root.auth);

  const messageListener = (event: MessageEvent<any>) => {
    const { data } = event;

    console.log("Message received from the parent: " + JSON.stringify(data));

    const { type } = data;

    if (!type) {
      return;
    }

    if (type === "sso_login") {
      const {
        merchant_id: merchantId,
        sso_customer_id: ssoCustomerId,
        sso_hash: ssoHash,
        email_address: emailAddress,
        customer_first_name: firstName,
        customer_surname: lastName,
        dob,
        ip_address: ipAddress,
        order_amount: orderAmount,
      } = data;

      saveSsoPayload({
        ...data,
        includeFee: +data.include,
      });

      dispatch(
        AppActions.auth.ssoAuth({
          merchantId,
          ssoCustomerId,
          ssoHash,
          emailAddress,
          firstName,
          lastName,
          dob,
          saasUserCorporationId: 1,
          ipAddress,
          orderAmount: RoundDown2Digits(orderAmount),
          next: (token: string, user: IUser) => {
            saveUser(user);
            saveToken(token);

            setLogIn(true);
            window.parent.postMessage(
              {
                type: "logged_in_success",
                token,
              },
              "*"
            );
          },
          kycRequired: (user: IUser) => {
            saveUser(user);
            navigate(PATHS.KYC);
            window.parent.postMessage(
              {
                type: "kyc_required",
              },
              "*"
            );
          },
          error: (error: any) => {
            resetCookies();

            window.parent.postMessage(
              {
                type: "logged_in_failure",
                errorCode: 400,
              },
              "*"
            );
          },
        })
      );
    }

    if (type === "cko-msg") {
      if (data.event === "cardValidationChanged") {
        setIsCardValid(data.data.isValid);
      } else if (data.event === "paymentMethodChanged") {
        setIsCardValid(data.data.isPaymentMethodAccepted);
        if (!data.data.isPaymentMethodAccepted && data.data.paymentMethod) {
          dispatch(
            AppActions.order.createErrorOrderStatus({
              message:
                "We don't support this card type. Please use another card or contact our support team.",
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    window.parent.postMessage({ type: "page_loaded" }, "*");
    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes(PATHS.CARD_INFO) ||
      location.pathname.includes(PATHS.PAYMENT_SUCCESS)
    ) {
    } else if (isLogIn) {
      if (!location.pathname.includes(PATHS.CARD_INFO))
        navigate(PATHS.CONFIRM_ORDER);
    } else {
      //      navigate("/");
    }
  }, [isLogIn, location.pathname, navigate]);

  useEffect(() => {
    dispatch(
      AppActions.crypto.getCryptos({
        saasUserCorporationId: saasUserCorporationId,
      })
    );
    dispatch(
      AppActions.currency.getCurrencies({
        saasUserCorporationId: saasUserCorporationId,
      })
    );
  }, [dispatch]);

  return (
    <div className="app flex flex-col h-screen">
      {isLoading && (
        <div className="fixed w-full h-full flex justify-center items-center">
          <div className={`w-36 h-36 z-20 animate-spin-slow`}>
            <PendingIcon className="fill-font-secondary w-36 h-36" />
          </div>
        </div>
      )}
      <Routes>
        <Route path={PATHS.CONFIRM_ORDER} element={<ConfirmOrderPage />} />
        <Route
          path={PATHS.CARD_INFO + "/*"}
          element={<CardInfoPage isCardValid={isCardValid} />}
        />
        <Route
          path={PATHS.PAYMENT_SUCCESS + "/*"}
          element={<PaymentSuccessPage />}
        />
        <Route path={PATHS.KYC} element={<VeriffAfterAuth />} />
        <Route path="*" element={<div></div>} />
      </Routes>
    </div>
  );
}

export default App;
