import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ellipsisAddress } from "utils/supports";
import {
  PATHS,
  RAMPING,
  TransactionTypeForFee,
  termsOfServiceLink,
} from "consts";
import { Button, BuyCryptoSummary, CheckBox } from "components";
import { cryptoSvgs } from "assets";
import { PlatformContext } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { useNavigate } from "react-router-dom";
import { RoundDown2Digits } from "utils";

export const ConfirmOrderPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<number>(0);
  const [includeFee, setIncludeFee] = useState<number>(1);
  const [crypto, setCrypto] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const { ssoPayload } = useContext(PlatformContext);

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isQuoteWork, setIsQuoteWork] = useState<boolean>(false);

  const { quote, error } = useSelector((root: RootState) => root.quote);
  const { fee } = useSelector((root: RootState) => root.fee);

  useEffect(() => {
    setIsQuoteWork(!error);
  }, [error]);

  const onClickContinue = () => {
    if (!error) {
      if (confirmation && ssoPayload) {
        // to do create order
        window.parent.postMessage(
          {
            type: "order_confirmed",
          },
          "*"
        );

        dispatch(
          AppActions.order.createSsoOrder({
            merchantId: ssoPayload.merchant_id,
            ssoCustomerId: ssoPayload.sso_customer_id,
            customerWalletAddress: ssoPayload.wallet_address,
            type: +ssoPayload.order_type,
            currency: ssoPayload.currency,
            crypto: ssoPayload.crypto_currency,
            orderAmount: amount,
            ssoOrderId: ssoPayload.sso_order_id,
            includeFee: ssoPayload.includeFee,
            next: () => {
              navigate(PATHS.CARD_INFO);
            },
            error: (msg: string) => {
              setErrorMsg(msg);
            },
          })
        );
      } else {
        setClicked(true);
      }
    }
  };

  const onCancelContinue = () => {
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
    setErrorMsg("");
  };

  useEffect(() => {
    if (ssoPayload) {
      setType(+ssoPayload.order_type);
      setCrypto(ssoPayload.crypto_currency);
      setCurrency(ssoPayload.currency);
      setWalletAddress(ssoPayload.wallet_address);
      setIncludeFee(ssoPayload.includeFee);

      dispatch(
        AppActions.fee.getFee({
          sendAmount: RoundDown2Digits(+ssoPayload.order_amount),
          currency: ssoPayload.currency,
          transactionType: +ssoPayload.order_type
            ? TransactionTypeForFee.RETAIL_OFF_RAMP
            : TransactionTypeForFee.RETAIL_ON_RAMP,
        })
      );
    }
  }, [dispatch, ssoPayload, type]);

  useEffect(() => {
    if (ssoPayload) {
      setAmount(RoundDown2Digits(+ssoPayload.order_amount));
    }
  }, [amount, quote, fee, type, ssoPayload, includeFee]);

  const copyWalletAddress = (walletAddress: string) => {
    window.parent.postMessage(
      { type: "addr_copied", address: walletAddress },
      "*"
    );
    navigator.clipboard.writeText(walletAddress);
  };

  return (
    <>
      <div className="flex justify-between p-4">
        <span className="text-base font-medium text-font-primary">
          Confirm Your Order
        </span>
        <button
          className="text-base font-medium text-font-primary"
          onClick={onCancelContinue}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col h-full justify-between p-4 mt-4">
        <div className="flex flex-col">
          <span className="text-base font-medium text-font-subtle">
            Delivering {ssoPayload?.nft_name ? "NFT" : crypto} to
          </span>
          <div className="flex justify-between mt-2 mb-4">
            <div className="flex">
              <img
                className="w-6 h-6"
                src={cryptoSvgs[crypto]}
                alt="Crypto Icon"
              />
              <span className="text-base font-medium text-font-primary ml-2">
                {ellipsisAddress(walletAddress)}
              </span>
            </div>
            <button
              className="text-sm font-bold text-primary"
              onClick={() => copyWalletAddress(walletAddress)}
            >
              Copy
            </button>
          </div>
          <BuyCryptoSummary
            currency={currency}
            crypto={crypto}
            type={type ? RAMPING.OFF : RAMPING.ON}
            amount={amount}
            includeFee={includeFee}
            nftName={ssoPayload?.nft_name}
          />
        </div>
        <div className="w-full mt-4">
          <div className="flex items-center mb-4">
            <CheckBox
              className="mr-4"
              error={!confirmation && clicked}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setConfirmation(event.target.checked);
                setClicked(false);
              }}
            />
            <p className="text-sm font-medium text-font-primary">
              I agree to WynPay’s&nbsp;
              <a
                className="text-primary"
                href={termsOfServiceLink}
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              &nbsp; and I authorise WynPay to debit my chosen payment method
              for the amount above on today’s date and understand that this can
              not be cancelled, recalled or refunded.
            </p>
          </div>
          {!confirmation && clicked && (
            <p className="text-sm font-medium text-feedback-warning mb-4">
              You must check this box to confirm your agreement with the Terms
              of Use to proceed.
            </p>
          )}
          {!isQuoteWork && (
            <p className="text-sm font-medium text-feedback-warning mb-4">
              Get Quote Service is not available and can’t place orders. Pls
              contact support.
            </p>
          )}
          <Button
            className="w-full"
            onClick={onClickContinue}
            disabled={!isQuoteWork}
          >
            Proceed to Payment
          </Button>
        </div>
      </div>
      {errorMsg && (
        <div className="fixed w-full h-full flex items-center justify-center">
          <div className="fixed -z-50 w-full h-full bg-black opacity-60"></div>
          <div className="z-100 flex flex-col items-center bg-white p-8 mx-4 border border-1 rounded-xl">
            <p className="text-md font-medium text-font-primary text-center">
              {errorMsg}
            </p>
            <Button className="h-12 py-1 px-4 mt-4" onClick={onCancelContinue}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
