import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { customerServerApiClient, ssoOrderServerApiClient } from "utils";
import { PATHS } from "consts";
import { PlatformContext } from "contexts";
import { OrderStatus } from "types";

export const PaymentSuccessPage: React.FC = () => {
  const location = useLocation();

  const { ssoPayload } = useContext(PlatformContext);

  const ssoOrderIdFromUrl =
    location.pathname.includes(`${PATHS.PAYMENT_SUCCESS}/`) &&
    location.pathname.replace(`${PATHS.PAYMENT_SUCCESS}/`, "");

  const ssoOrderId = ssoPayload?.sso_order_id;

  useEffect(() => {
    window.parent.postMessage(
      {
        type: "order_completed",
      },
      "*"
    );
  }, []);

  // useEffect(() => {
  //   try {
  //     ssoOrderServerApiClient
  //       .post("/notify-order-status", {
  //         ssoOrderId: ssoOrderId ? ssoOrderId : ssoOrderIdFromUrl,
  //         failReasons: "",
  //         status: "S",
  //       })
  //       .then(() => {})
  //       .catch((err) => console.log("Notification lambda error", err));
  //     customerServerApiClient
  //       .patch(`/order/sso/${ssoOrderId ? ssoOrderId : ssoOrderIdFromUrl}`, {
  //         status: OrderStatus.PROCESSING,
  //       })
  //       .then(() => {})
  //       .catch((err) => console.log("Updating order status error", err));
  //   } catch (error) {
  //     console.log("error");
  //   }
  // }, []);

  return <></>;
};
