import { cancelInCircleSvg, infoSvg } from "assets";
import { RAMPING } from "consts";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { IFeeIncludeOptions } from "types";
import { getCrypto, getFiat } from "utils/supports";

interface IProps {
  type: RAMPING;
  crypto: string;
  currency: string;
  amount: number;
  includeFee: IFeeIncludeOptions;
  nftName?: string;
}

export const BuyCryptoSummary: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { type, amount, crypto, currency, includeFee, nftName } = props;

  const [updateIn, setUpdateIn] = useState<number>(10);
  const { quote, error } = useSelector((state: RootState) => state.quote);
  const { currencies } = useSelector((state: RootState) => state.currency);
  const { cryptos } = useSelector((state: RootState) => state.crypto);
  const { fee } = useSelector((state: RootState) => state.fee);
  const [isMarketLink, setMarketLink] = useState<boolean>(false);

  const feeAmount = useMemo(() => {
    if (includeFee === IFeeIncludeOptions.EXCLUDE) {
      return amount + +fee.fee;
    }
    return amount;
  }, [amount, includeFee, fee.fee]);

  const getQuotes = () => {
    const ticker = cryptos.find((el) => el.shortName === crypto)?.ticker;

    if (ticker) {
      dispatch(
        AppActions.quote.getQuote({
          side: type,
          quantity: 1,
          crypto: ticker,
          currency,
        })
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setUpdateIn((updateIn) => (updateIn > 0 ? updateIn - 1 : 10));
    }, 1000);

    if (updateIn === 0) {
      getQuotes();
    }
  }, [updateIn]);

  useEffect(() => {
    getQuotes();
  }, [amount, crypto, currency, currencies, cryptos]);

  const _currency = useMemo(() => {
    return currencies.find((el) => el.code === currency);
  }, [currency, currencies]);

  useEffect(() => {
    if (fee.fee > 0) {
      setMarketLink(true);
    } else {
      setMarketLink(false);
    }
  }, [fee.fee]);

  return (
    <>
      <div className="flex justify-between items-end mb-2">
        <label className="text-base font-medium text-font-primary">
          Summary
        </label>
        <label className="text-xs font-medium text-gray-400">
          Quote updates in {updateIn}s
        </label>
      </div>
      {!!error && (
        <div
          className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 ml-4 mr-4 mb-4 mt-4"
          onClick={() => {}}
        >
          <img src={cancelInCircleSvg} />
          <p className="text-sm font-medium text-font-secondary ml-2">
            Get Quote Service is not available and can’t place orders. Pls
            contact support
          </p>
        </div>
      )}
      <div className="bg-feedback-information-tint divide-y p-4 rounded-xl">
        <span className="text-base font-medium text-font-primary mb-2">
          You get{" "}
          <span className="font-bold">
            {!!nftName
              ? nftName
              : {
                  [RAMPING.ON]: (
                    <>
                      {getCrypto(amount, quote, fee.fee, includeFee)} {crypto}
                    </>
                  ),
                  [RAMPING.OFF]: (
                    <>
                      ~{_currency && _currency.symbol}
                      {getFiat(amount, quote, fee.fee, includeFee)}
                    </>
                  ),
                }[type]}
          </span>{" "}
          for{" "}
          <span className="font-bold">
            {!!nftName ? (
              <>
                {_currency && _currency.symbol}
                {feeAmount.toFixed(2)}
              </>
            ) : (
              {
                [RAMPING.ON]: (
                  <>
                    {_currency && _currency.symbol}
                    {feeAmount.toFixed(2)}
                  </>
                ),
                [RAMPING.OFF]: (
                  <>
                    {feeAmount.toFixed(5)} {crypto}
                  </>
                ),
              }[type]
            )}
          </span>
        </span>
        <div className="mt-4">
          {!nftName && (
            <div className="flex justify-between items-end mt-4">
              <div>
                <span className="text-sm font-medium text-font-primary mr-1">
                  {(
                    (feeAmount -
                      (includeFee === IFeeIncludeOptions.MERCHANT
                        ? 0.0
                        : fee.fee)) /
                    +quote.liquidityQuotes
                  ).toFixed(5)}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  @ {_currency && _currency.symbol}
                  {(+quote.liquidityQuotes).toFixed(2)}
                </span>
              </div>
              <span className="text-sm font-medium text-font-primary">
                {_currency && _currency.symbol}
                {(
                  feeAmount -
                  (includeFee === IFeeIncludeOptions.MERCHANT ? 0.0 : fee.fee)
                ).toFixed(2)}
              </span>
            </div>
          )}

          <div className="flex justify-between items-end mt-3">
            <div className="flex">
              <span className="text-sm font-medium text-font-primary mr-1">
                Processing fee
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-xs font-medium text-gray-500 mr-1">
                as low as
              </span>
              <span className="text-sm font-medium text-font-primary">
                {_currency && _currency.symbol}
                {includeFee === IFeeIncludeOptions.MERCHANT ? "0.00" : fee.fee}
              </span>
            </div>
          </div>
          {isMarketLink && (
            <div className="flex mt-1">
              <img src={infoSvg} />
              <div className="">
                <p className="text-xs font-medium text-gray-500">
                  To be able to compare the quoted rate against the market rate
                  please follow this{" "}
                  <a
                    className="text-primary"
                    href="https://www.coinbase.com/explore"
                    target="_blink"
                  >
                    link
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BuyCryptoSummary;
